import img from "../../images/about/menInstalling.jpg";

export const generalGalleries = [
  {
    link: "/gallery/project1",
    image: img,
    alt: "",
    title: "Project #1",
    location: "MESQUITE, TEXAS",
  },
];
